.container-box {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;
}
.bg-dark-blue {
  background-color: #061538;
}
.bg-light-gray {
  background-color: #f2f2f2;
}
::selection {
  background-color: #c61d23;
  color: white;
}
.text-dark-blue {
  color: #061538;
}
.text-orange {
  color: #c61d23;
}
.contact-icon {
  color: #c61d23;
  font-size: 14px;
}
.orange-btn {
  border-radius: 0;
  font-size: 16px;
  padding: 11.5px 51px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #c61d23;
  color: white;
  border: none;
  transition: 0.3s;
  text-decoration: none;
}
.orange-btn:hover {
  background-color: #061538 !important;
  border-color: #061538 !important;
}
.orange-outline-btn {
  background-color: transparent;
  padding: 10.5px 0;
  border: 1px solid #c61d23;
}
.top-heading {
  display: flex;
  align-items: center;
}
.top-heading img {
  width: 50px;
  height: 65px;
}
.top-heading span {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #c61d23;
}
.top-heading h3 {
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  color: #061538;
}
.paragraph {
  line-height: 26px;
  font-weight: 400;
  text-align: justify;
  color: #242424;
}
.mt-35 {
  margin-top: 35px;
}

/* page header */
.page-header {
  background-image: url("./assets/images/page-header-bg.jpg");
  background-size: cover;
  background-position: 40% 40%;
}
.circle-icon {
  width: 65px;
  height: 65px;
  border-radius: 50px;
  display: grid;
  place-items: center;
  transition: 0.5s;
  background-color: #fff;
  font-size: 24px;
  box-shadow: 2px 2px 20px #22222296;
}
.circle-icon:hover svg {
  color: #c61d23 !important;
}
.social-media-icons {
  position: absolute;
  left: 0;
  top: -28.6%;
  width: 100%;
}
.header-path-link {
  font-size: 19px;
}

/* accordian */
.accordian-element {
  box-shadow: 0px 5px 35px 2px rgba(0, 0, 0, 0.06);
  border: 1px solid #d3d3d362;
}
.accordian-element li {
  list-style: none;
}
.accordian-element li label {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 17px;
  font-weight: bold;
  border-bottom: 1px solid #d3d3d362;
  background-color: white;
  cursor: pointer;
  line-height: 22px;
  color: #061538;
}
.accordian-element li label::before {
  content: "+";
  margin-right: 12px;
  font-size: 24px;
  font-weight: 700;
  transform: translateY(-2px);
}
.accordian-element li input {
  display: none;
}
.accordian-element li .content {
  max-height: 0;
  overflow: hidden;
  padding: 0px 20px;
  transition: max-height 0.3s;
  transition-timing-function: ease-in;
}
.accordian-element input[type="radio"]:checked+label+.content {
  max-height: 500px;
  padding: 10px 20px;
  border-bottom: 1px solid #d3d3d362;
}
.accordian-element input[type="radio"]:checked+label::before {
  content: "–";
}
.search-bar input {
  width: 80%;
  font-size: 17px;
  outline: none;
  border: 2px solid #061538;
}
.search-bar button {
  width: 20%;
  border: 2px solid #061538;
  background-color: #061538;
  color: white;
}

/* responsive design */
@media screen and (max-width: 1300px) {
  .container-box {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
}

@media (min-width: 768px) and (max-width: 960px) {
  .social-media-icons {
    top: -27%;
  }
}

@media screen and (max-width: 600px) {
  .top-heading h6 {
    font-size: 20px;
  }
  .social-media-icons {
    top: -23.8%;
  }
  .top-heading h3 {
    font-size: 28px;
    margin-bottom: 0;
  }
  .header-path-link {
    font-size: 16px;
    line-height: 25px;
  }
  .top-heading-terms h3 {
    padding-top: 20px;
  }
  .container-box-terms{
    padding-bottom: 1.5rem;
  }
}

@media screen and (max-width: 445px) {
  .social-media-icons {
    top: -22%;
  }
}

@media screen and (max-width: 360px) {
  .slider-content .orange-btn {
    padding: 10px 15px !important;
  }
}

.scroll-reset {
  transform: translateY(0);
}
.image-container {
  position: relative;
  overflow: hidden;
}
.image-container img {
  transition: all .5s;
  transform: scale(1.17);
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(6, 21, 56, 0.2);
}
.image-container:hover img {
  transform: scale(1);
}

.container-box-terms {
  padding-left: 7.5% !important;
  padding-right: 7.5% !important;
}

.top-heading-terms span {
  display: inline-block;
  font-size: 24px;
  font-weight: 550;
  
  color: #061538;
  padding-bottom: 5px;
 
}
.top-heading-terms h3 {
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  
  margin-bottom: 25px;
  color: #061538;
}
.top-heading-terms p {
 font-size: 18px;
}
.top-heading-terms li {
  font-size: 18px;
 }

 .top-heading-terms a{
  text-decoration: none;
  color: #c61d23
 }

 /* terms */

.technical-solutions-terms li {
  list-style: none;
  position: relative;
  line-height: 30px;
}
.technical-solutions-terms li::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -2%;
  background-color: #c61d23;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  -webkit-box-shadow: inset 0 0 1px 2px #fff;
  box-shadow: inset 0 0 1px 2px #fff;
  border: 1px solid #c61d23;
}

@media screen and (max-width:600px) and (min-width:440px){
  .technical-solutions-terms li::before {
    left: -5%;
    
  }
}
@media screen and (max-width:440px){
  .technical-solutions-terms li::before {
    left: -5%;
    
  }
}
@media screen and (max-width:760px) and (min-width:600px){
  .technical-solutions-terms li::before {
    left: -4%;
    
  }
}

/* hide arrows from input=number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.hover-red:hover {
  color: #c61d23 !important;
}
.fw-500 {
  font-weight: 500;
}