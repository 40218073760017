/* Navbar */
.contact-details a {
  color: white;
  text-decoration: none;
  font-weight: 300;
}
.social-icon {
  color: white;
  margin-left: 20px;
}
.social-icon:hover {
  color: #0077b5;
}
.logo {
  width: 160px;
  height: auto;
}
.navibar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 10px #80808053;
  height: 80px;
  position: relative;
}

/*  sticky navbar */
.stickyNav {
  position: fixed !important;
  background-color: #fff;
  box-shadow: 0 4px 12px -4px #00000049;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  animation: fadedownNav 0.8s ease-in-out 0s 1 normal none running;
  z-index: 999;
}

@keyframes fadedownNav {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/*  */

.navlinks {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
  height: 100%;
}
.navlinks li {
  height: 100%;
  padding-top: 0 !important;
}
.navlinks li a {
  color: #242424;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 600;
  color: #242424;
  display: inline-block;
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
  position: relative;
}
.navlinks li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 35%;
  width: 20px;
  height: 4px;
  background: #c61d23;
  transform: translateX(-50%);
  transition: 0.5s;
  opacity: 0;
}
.navlinks li:hover a::before {
  opacity: 1;
  left: 50%;
}
.navlinks li a:hover {
  color: #c61d23;
}
.navlinks li .active {
  color: #c61d23;
}
.menu-btn {
  display: none;
}

/* carousel */
.carousel-slide {
  position: relative;
}
.carousel-slide>img {
  object-fit: cover;
  height: 105vh;
  width: 100%;
}
.slider-content {
  position: absolute;
  z-index: 99;
  top: 28%;
  left: 7.5%;
  width: 85%;
}
.slider-content h2 {
  font-size: 49px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 5px;
}
.slider-content p {
  font-size: 18px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 0;
}
.carousel-btns {
  position: absolute;
  bottom: 18%;
  width: 85%;
  left: 7.5%;
  text-align: center;
}
.carousel-btns button {
  border: none;
  padding: 5px;
  width: 16px;
  margin: 0 5px;
  border-radius: 20px;
  background-color: #a19fa3;
  transition: all 0.4s;
}
.currSlide {
  width: 30px !important;
  background-color: #c61d23 !important;
}

/* footer section */
footer {
  background-color: #0b0b0b;
}
.footer-logo {
  width: 165px;
}
.top-footer {
  padding: 35px 0;
  border-bottom: 1px solid #292929;
}
.subscription-form input {
  width: 215px;
  padding: 10px 23px;
  border-radius: 0;
  border: none;
  margin-right: 18px;
}
.bottom-footer {
  background-color: #0b0b0b;
  padding: 60px 0 70px;
  border-top: 1px solid #292929;
}
.footer-links {
  list-style: none;
  padding: 0;
}
.footer-links li {
  margin-bottom: 0 !important;
  padding-bottom: 0;
  line-height: 30px;
}
.footer-links li a {
  color: #A6A6A6;
  position: relative;
  padding-left: 12px;
  padding-bottom: 0;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  margin-left: 24px;
  height: 100%;
  display: block;
  border-left: 2px solid #2b2a2a;
}
.footer-links li a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -25px;
  height: 1px;
  width: 25px;
  background-color: #6f6c6c;
  transform: translateY(-50%);
}
.footer-links li a:hover {
  color: #c61d23;
}
.footer-links li a:hover::before {
  background-color: #c61d23;
}
.recent-post img {
  height: 80px;
  width: 105px;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .recent-post img {
    height: 75px;
  }
}
.recent-post p {
  color: #c61d23;
}
.recent-post span {
  color: #A6A6A6;
  line-height: 26px;
}
.footer-social-icon {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 1px solid #ddd;
  margin-right: 15px;
  display: inline-block;
  padding: 4px 10px 10px;
  color: #fff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.copyright {
  background-color: #141414;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
}
.copyright p {
  font-size: 15.5px;
}
.copyright p a {
  text-decoration: none;
  color: #c61d23;
}

/* --------------------------------- */

/* inspiring section */

.inspiring-section {
  /* background-color: #f2f2f2; */
}
.owner-bg {
  background-image: linear-gradient(to right, rgb(255, 255, 255, .6), rgb(6, 21, 56, .2)), url('../images/owner-bg.avif');
  background-size: cover;
}
h6.inspiring-author-role {
  font-size: 16px;
  font-weight: 600;
  color: #c61d23;
}
.ownerImage {
  box-shadow:  2px 2px 20px #061538b4;
}

/* services section */

.product-img-box {
  position: relative;
  overflow: hidden;
}
.product-img-box img {
  width: 100%;
  height: 250px;
}
.service-img-box img {
  height: auto;
}
.shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  transition: 0.5s;
  border-top: 100px solid #061538;
  border-left: 100px solid transparent;
  z-index: 2;
}
.product-icon {
  position: absolute;
  color: white;
  display: inline-block;
  top: 7.5%;
  right: 5%;
  font-size: 28px;
  z-index: 3;
  transition: 0.5s;
}
.hover-effect-box {
  position: absolute;
  background-color: #061538a8;
  top: -6%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: 0.5s;
}
.hover-effect-box>div {
  position: absolute;
  width: 84%;
  height: 80%;
  left: 8%;
  top: 10%;
  outline: 1px solid #c61d23;
  display: grid;
  place-items: center;
}
.link-icon {
  font-size: 25px;
  transform: rotate(-100deg);
}
.product-content h4 {
  font-weight: 600;
}
.product-card:hover .product-img-box .shape {
  border-top: 110px solid #c61d23;
  border-left: 110px solid transparent;
}
.product-card:hover .product-icon {
  top: 8.5%;
  right: 7%;
}
.product-card:hover .hover-effect-box {
  top: 0;
  opacity: 1;
}
.product-card:hover .product-content h4 {
  color: #c61d23;
}

/* why choose us */
.technical-solutions li {
  list-style: none;
  position: relative;
  line-height: 28px;
}
.technical-solutions li::before {
  content: "";
  position: absolute;
  top: 6px;
  left: -4%;
  background-color: #c61d23;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  -webkit-box-shadow: inset 0 0 1px 2px #fff;
  box-shadow: inset 0 0 1px 2px #fff;
  border: 1px solid #c61d23;
}

/* testimonial section */
.testimonial-bg {
  background-image: url("../images/testimonial-bg.webp");
  background-size: cover;
  background-position: 50% 20%;
  background-attachment: fixed;
}
.testimonial-bg:active {
  cursor: grab;
}
.testimonial-slide {
  outline: 1px solid #afacacab;
  padding: 45px;
  min-height: 370px;
  box-shadow: 2px 2px 50px #afacac42 inset;
}
.star-icon {
  font-size: 14px;
  margin-right: 5px;
}

/* contact section */
.contact-field {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid rgb(186, 181, 181);
  outline: none;
  transition: 0.4s;
  color: #242424;
  font-size: 16px;
}
.contact-field:focus {
  border: 1.6px solid #c61d23;
}
.contact-banner {
  background-image: linear-gradient(#06153897, #06153897),
    url("../images/telephone-bg.webp");
  background-size: cover;
  background-position: center;
}
.contact-banner p {
  font-size: 17px;
}
.banner-btn {
  font-size: 19px;
}
.banner-btn:hover {
  background-color: white !important;
  color: #c61d23;
}

/* blog section */
.blog-card-content {
  min-height: 200px !important;
}
.blog-card:active {
  cursor: grab;
}
.blog-card:hover .blog-card-content h5 {
  color: #c61d23;
}
.blog-card .top-img img {
  width: 100%;
  transition: 0.2s;
  height: 260px;
  object-fit: cover;
}
.blog-card .top-img {
  position: relative;
  overflow: hidden;
}
.blog-card .top-img>div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: #111111d5;
  opacity: 0;
  transition: 0.8s;
}
.blog-date {
  font-size: 15px;
}
.blog-desc {
  font-size: 16px;
  margin-top: 16px;
  line-height: 27px;
}
.blog-card:hover .top-img>div {
  opacity: 1;
}

.blog-card:hover .top-img img {
  transform: scale(1.08);
}
.blogs .slick-next:before {
  color: #061538;
}
.blogs .slick-prev:before {
  color: #061538;
}

/* dynamic content blog */
.blog-dynamic-content p a {
  color: #c61d23;
  font-weight: 500;
}
.vc_row.section-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.vc_row .section-element:last-child {
  margin-bottom: 0px;
}
.vc_col-sm-3 {
  width: 22%;
}
.vc_col-sm-3 img {
  width: 100%;
}
.vc_col-sm-9 {
  width: 72%;
}

@media screen and (max-width: 768px) {
  .vc_col-sm-3 {
    width: 55%;
  }
  .vc_col-sm-9 {
    width: 100%;
  }
}

/* client section */
.client-logo {
  transition: all .4s;
}
.client-logo:hover {
  transform: scale(1.1);
}

/* About US page */
.progress-bar {
  border: 1px solid lightgray;
}
.progress-fill {
  height: 8px;
  background-color: #c61d23;
}
.border-dashed-bottom {
  border-bottom: 2px dashed lightgray;
}

/* vesda system page */
.left-product-links a {
  display: block;
  padding: 15px;
  background-color: #f4f4f4;
  font-size: 18px;
  font-weight: 600;
  color: #061538;
}
.left-product-links a:hover {
  background-color: #061538;
  color: white;
}
.left-product-links a.active {
  background-color: #061538;
  color: white;
  border-right: 5px solid #c61d23;
}
.request-brochures a {
  padding: 18px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #061538;
  color: white;
}
.request-brochures svg {
  color: #c61d23;
  font-size: 22px;
}
.contact-details-box {
  padding: 40px 30px;
  background-color: #c61d23;
  color: white;
}
.product-detail-img {
  height: 450px;
  object-fit: cover;
}

/* blog details */
.popular-post img {
  width: 100px;
  height: 75px;
  object-fit: cover;
}
.popular-post p {
  font-weight: 600;
}
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags a {
  padding: 8px 14px;
  border: 1px solid rgba(211, 211, 211, 0.576);
  color: #0a2155;
  margin-right: 6px;
  margin-top: 6px;
  text-transform: capitalize;
}
.tags a:hover {
  background-color: #c61d23;
  color: #fff;
}
.next-prev-btn button {
  color: #c61d23;
  font-size: 18px;
  padding: 6px 14px;
  border-radius: 5px;
  font-weight: 600;
}
.next-prev-btn button:hover {
  background-color: #1111111b !important;
}

/* contact us page */
.contact-form {
  background-image: linear-gradient(to right, rgb(6, 21, 56, .6), rgb(6, 21, 56, .6)), 
                    url('../images/contact-form-bg.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.contact-svg-img {
  max-height: 80px;
}
.enquiry-form {
  background-image: linear-gradient(to right, rgb(6, 21, 56, .85), rgb(6, 21, 56, .5)),
                    url('../images/enquiry-form-bg.webp');
  background-size: cover;
  background-position: left;
}



/* animations */
.customFadeUp {
  animation: fadeUps 1s ease-in-out .1s 1 normal none running;
}

@keyframes fadeUps {
  0% {
    transform: translateY(140px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* responsive design */
@media screen and (max-width: 1300px) {
  .navlinks li a {
    padding: 0 14px;
    font-size: 15px;
  }
  .slider-content {
    left: 5%;
    width: 90%;
  }
}

@media screen and (max-width: 1150px) {
  .navlinks li a {
    padding: 0 8px;
    font-size: 13px;
  }
}

@media screen and (max-width: 950px) {
  .navlinks {
    position: absolute;
    flex-direction: column;
    background-color: #061538;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: 0px;
    overflow: hidden;
    z-index: 10;
    justify-content: space-evenly !important;
    transition: all .5s;
    opacity: 0;
    box-shadow: 1px 1px 25px #12101074;
    border-top: 2px solid #c61d23;
  }
  .showNavbar {
    height: 350px;
    opacity: 1;
  }
  .menu-btn {
    display: block;
  }
  .navlinks li {
    width: 100%;
  }
  .navlinks li a {
    color: white;
    padding-left: 30px;
    padding: 0px 30px;
  }
  .navlinks li a::before {
    left: 0;
  }
  .navlinks li:hover a::before {
    opacity: 1;
    left: 10%;
  }
  .slider-content {
    top: 22%;
    left: 5%;
    width: 90%;
  }
  .slider-content h2 {
    font-size: 40px;
    font-weight: 600;
  }
  .slider-content p {
    font-size: 17px;
  }
  .subscription-form input {
    width: 180px;
  }
  .copyright {
    flex-direction: column;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .subscription-form input {
    width: 100%;
    margin-top: 20px;
  }

  .subscription-form .orange-btn {
    margin-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .slider-content {
    top: 15%;
  }
  .slider-content h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .slider-content p {
    font-size: 16px;
  }
  .carousel-slide>img {
    height: 94vh;
  }
  .inspiring-section {
    padding: 60px 0;
  }
  .technical-solutions li::before {
    left: -7%;
    top: 18%;
  }
  
  .testimonial-slide {
    padding: 22px;
  }
  /* contact us */
  .contact-info div div h5 {
    font-size: 17px;
  }
  /* PRODUCT DETAILS */
  .product-detail-img {
    height: 250px;
  }

  /* blog details */
  .comment-img {
    height: 90px;
    width: auto;
  }
  .comment-img+div p {
    font-size: 15px;
  }
  .next-prev-btn button {
    font-size: 16px;
  }
  /* footer */
  .bottom-footer {
    padding: 30px 0 60px;
  }
}
@media screen and (max-height: 500px) {
  .carousel-slide>img {
    object-fit: cover;
    height: 150vh;
  }
  .slider-content {
    top: 10%;
  }
  .showNavbar {
    height: 66vh;
  }
}
@media screen and (max-width: 356px) {
  .orange-btn {
    padding: 10px 15px !important;
  }
}
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #111111a0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader img {
  width: 300px;
}

/* pagination on mehul goswami page */
.page {
  padding: 15px 26px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
.page:hover {
  background-color: #c61d231f;
  border: 1px solid #c61d231f !important;
}
.page.active {
  background-color: #c61d23;
  color: #fff;
  border: 1px solid #c61d23 !important;
}
@media screen and (max-width: 600px) {
  .page {
    padding: 12px 22px;
  }
  .contact-form,
  .testimonial-bg {
    background-attachment: scroll;
  }
}

