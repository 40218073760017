@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');

* {
  text-decoration: none !important;
}

body {
  margin: 0;
  font-family: 'Hind', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 26px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}